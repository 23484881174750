import { useToasteo } from '@deegital/vue-trustup-io-toasteo';
import { ref } from 'vue';
import WorksiteProgressBillingEndpoint from '../app/worksite/endpoints/WorskiteProgressBillingEndpoint';
import WorksiteProgressBilling from '../app/worksite/models/WorksiteProgressBilling';
import WorksiteProgressBillingForm from '../app/worksite/classes/WorksiteProgressBillingForm';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import EndpointFactory from '~~/app/factories/EndpointFactory';

export interface PdfInterface {
    model_type: string;
    model_id: number;
    url: string;
    uuid: string;
    updated_at: string;
    created_at: string;
    id: number;
}

export default function () {
    // interface Fields {}
    // const form = useForm<Fields>({})

    const toasteo = useToasteo();

    const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROGRESS_BILLINGS) as WorksiteProgressBillingEndpoint;

    const worksiteProgressBilling = ref<WorksiteProgressBilling | null>(null);

    endpoint.setInclude('quote,worksiteProgressBillingLines.invoiceLine,worksiteProgressBillingLines.invoiceLine.productUnit,worksite');

    const validate = async (progressBilling?: WorksiteProgressBilling, form?: WorksiteProgressBillingForm) => {
        console.log(progressBilling, form);
        if (progressBilling?.status === 'draft') {
            useEvent('worksite:worksite-progress-billing:force-saving-progress-billing-modal:open', { progressBilling, form });
        }
        if (progressBilling?.status !== 'validation_requested') return;
        const response = await endpoint.validated(progressBilling?.getId());

        if (response.error) useToasteoError();
        useEvent('worksite:worksite-progress-billing:updated', response.data);
        useToasteoSuccess();
    };

    const downloadPdf = async (worksiteProgressBilling?: WorksiteProgressBilling) => {
        const response = await endpoint.pdf(worksiteProgressBilling?.getId() as string);
        if (response.error) useToasteoError();
        if (!response.data?.url) return useToasteoError();
        const link = document.createElement('a');
        link.href = response.data?.url as string;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
    };

    const transformToInvoice = (worksiteProgressBilling?: WorksiteProgressBilling) => {
        useEvent('worksite:worksite-progress-billing:invoice-modal:open', worksiteProgressBilling);
    };

    const openSettings = async (form: WorksiteProgressBillingForm) => {
        useEvent('worksite:worksite-progress-billing:setting-modal:open', form);
    };

    return {
        downloadPdf,
        validate,
        transformToInvoice,
        openSettings,
    };
}
